.title {
    font-family: "Readex Pro", sans-serif;
    max-width: 255px;
    max-height: 100%;
    border-radius: 20px;
    margin: 20px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease;
    text-decoration: none;
}

/*----------*/

.anime-img-wrapper {
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
}

.anime-img-wrapper::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
}

.anime-img-wrapper:hover .poster {
    filter: blur(3px);
}

.anime-img-wrapper:hover .fav-button {
    opacity: 1;
}

.anime-img-wrapper:hover .anime-episodes {
    opacity: 1;
}

.anime-img-wrapper:hover::before {
    opacity: 1;
}

/*----------*/

.poster-container {
    display: flex;
    position: relative;
}

.poster {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    transition: filter 0.3s ease-in-out;
}

/*----------*/

.anime-episodes {
    position: absolute;
    bottom: 55px;

    left: 0;
    right: 0;
    padding: 10px;

    background: rgb(255, 0, 92);
    color: #fff;
    font-size: 14px;
    text-align: center;
    transition: all 0.3s ease;
    opacity: 0;
    z-index: 2;
}

/*----------*/

.title-name {
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 1.2em;
    font-weight: bold;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* margin-bottom: 0.5em; */
}

.genres {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 13px;
    font-size: 0.9em;
    color: #bbb;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}



@media (max-width: 1525px) {
    .title {
        max-width: 190px;
    }

    .title-name {
        padding-top: 5px;
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {

    .title {
        max-width: 155px;
        margin: 15px;
    }

    .title-name {
        display: none;
        font-size: 0.8em;
    }

    .genres {
        margin-top: 5px;
        padding-bottom: 10px;
    }
}