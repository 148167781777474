.selectors {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 5px 5px 5px 0;
}

.selectors-left{
    display: flex;
}

.selectorLeft {
    margin: 5px 10px 5px 0;
    z-index: 20;
}

.selectorRight {
    margin: 5px 0 5px 0;
    z-index: 20;
}

/* Add a wrapper div for the second selector */
.selectors-right {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
}

@media (max-width: 915px) {
    .selectors-right {
        justify-content: space-between;
    }
}