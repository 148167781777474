.popup-h3 {
  font-family: "Readex Pro", sans-serif;
  margin-top: 0;
  margin-bottom: 10px;
}

.svg-heart{
  cursor:pointer; overflow:visible; width:60px;
  #heart{transform-origin:center; animation:animateHeartOut .3s linear forwards;}
  #main-circ{transform-origin:29.5px 29.5px;}
}

#checkbox{display:none;}

#checkbox:checked + label svg{
  #heart{transform:scale(.2); fill:#ce0045; animation:animateHeart .3s linear forwards .25s;}
  #main-circ{transition:all 2s; animation:animateCircle .3s linear forwards; opacity:1;}
  #grp1{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(0, -30px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(10px, -50px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp2{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(30px, -15px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(60px, -15px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp3{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(30px, 0px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(60px, 10px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp4{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(30px, 15px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(40px, 50px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp5{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(-10px, 20px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(-60px, 30px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp6{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(-30px, 0px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(-60px, -5px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp7{
    opacity:1; transition:.1s all .3s;
    #oval1{
      transform:scale(0) translate(-30px, -15px);
      transform-origin:0 0 0;
      transition:.5s transform .3s;}
    #oval2{
      transform:scale(0) translate(-55px, -30px);
      transform-origin:0 0 0;
      transition:1.5s transform .3s;}
  }
  #grp2{opacity:1; transition:.1s opacity .3s;}
  #grp3{opacity:1; transition:.1s opacity .3s;}
  #grp4{opacity:1; transition:.1s opacity .3s;}
  #grp5{opacity:1; transition:.1s opacity .3s;}
  #grp6{opacity:1; transition:.1s opacity .3s;}
  #grp7{opacity:1; transition:.1s opacity .3s;}
}

@keyframes animateCircle{
  40%{transform:scale(10); opacity:1; fill: #f1588b;}
  55%{transform:scale(11); opacity:1; fill: #dc2e66;}
  65%{transform:scale(12); opacity:1; fill: #cb1753;}
  75%{transform:scale(13); opacity:1; fill:transparent; stroke: #d20c4c; stroke-width:.5;}
  85%{transform:scale(17); opacity:1; fill:transparent; stroke:#ce0045; stroke-width:.2;}
  95%{transform:scale(18); opacity:1; fill:transparent; stroke:#ce0045; stroke-width:.1;}
  100%{transform:scale(19); opacity:1; fill:transparent; stroke:#ce0045; stroke-width:0;}
}

@keyframes animateHeart{
  0%{transform:scale(.2);}
  40%{transform:scale(1.2);}
  100%{transform:scale(1); stroke-width:0}
}

@keyframes animateHeartOut{
  0%{transform:scale(1.4);}
  100%{transform:scale(1);}
}