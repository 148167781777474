.title {
    max-width: 187px;
    max-height: 100%;
    border-radius: 20px;
    margin: 20px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transform: scale(1);
    transition: all 0.3s ease;
    text-decoration: none;
}

.title:hover {
    transform: scale(1.05);
}

.poster {
    border-radius: 20px;
}

@media (max-width: 962px) {
    .title{
        width: 120px;
    }
}