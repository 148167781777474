body {
  font-family: 'Montserrat', sans-serif;
  /*cursor: none;*/
}

::selection {
  color: white;
  background-color: #cc0049;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ff005c;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #cc0049;
}

/*.cursor {*/
/*  position: fixed;*/
/*  z-index: 9999;*/
/*  pointer-events: none;*/
/*  width: 15px;*/
/*  height: 15px;*/
/*  border-radius: 50%;*/
/*  background-color: #ff005c;*/
/*  box-shadow: 0 0 5px 2px rgba(51, 51, 51, 0.5);*/
/*  transform: translate(-50%, -50%);*/
/*}*/
/*.circle {*/
/*  position: fixed;*/
/*  z-index: 9998;*/
/*  pointer-events: none;*/
/*  width: 50px;*/
/*  height: 50px;*/
/*  border-radius: 50%;*/
/*  background-color: #3333330c;*/
/*  transform: translate(-50%, -50%);*/
/*}*/
/*.circle::before {*/
/*  content: "";*/
/*  position: absolute;*/
/*  inset: 0;*/
/*  border-radius: 50%;*/
/*  backdrop-filter: blur(1.8px);*/
/*}*/
