.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    opacity: 0;
}

.outer-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 30px;
    margin-bottom: 10px;
}

.title-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 20px 100px -10px;
    max-width: 954px;
}

.title-rating{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating{
    margin-top: 5px;
}

.poster-container {
    display: flex;
    position: relative;
}

.poster {
    display: flex;
    max-width: 253px;
    height: auto;
    border-radius: 20px;
    cursor: pointer;
}


.modalPoster {
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
    margin-bottom: 15px;
}

.fav-button {
    position: absolute;
    width: 65px;
    top: -5px;
    right: -5px;
}


.title-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    flex-grow: 1;
}

.title-name-description {
    margin-bottom: 10px;
}

.name {
    font-family: 'Readex Pro', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 10px;
}

.description {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    font-family: 'Readex Pro', sans-serif;
    cursor: pointer;
}

.modalName {
    font-family: 'Readex Pro', sans-serif;
    font-size: 30px;
    font-weight: bold;
}

.modalDescription {
    font-size: 16px;
    font-family: 'Readex Pro', sans-serif;
}

.anime-specifications {
    font-size: 14px;
}

.ppp {
    margin: 0;
    font-size: 18px;
}

.name-spec {
    font-family: 'Readex Pro', sans-serif;
}

.player {
    width: 100%;
    max-width: 954px;
}

.outer-franchises-container {
    text-align: center;
    border-radius: 30px;
    width: 100%;
    padding: 20px 0;
    max-width: 1400px;
    margin: 10px 20px;
}


.outer-franchises-container h2{
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 10px;
}

.mobile-description{
    display: none;
    font-family: 'Readex Pro', sans-serif;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
}


.buttons{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 20px;
}

@media only screen and (max-width: 782px) {

    .title-container {
        margin: 10px 20px 10px;
    }

    .poster {
        max-width: 150px;
    }

    .fav-button {
        width: 50px;
        margin-right: 5px;
    }

    .name {
        -webkit-line-clamp: 3;
        font-size: 20px;
        margin-top: 0;
    }

    .description {
        opacity: 0;
        display: none;
    }

    .modalName {
        font-size: 25px;
    }

    .modalDescription {
        display: none;
    }

    .ppp {
        font-size: 14px;
    }

    .anime-specifications {
        font-size: 12px;
    }

    .outer-franchises-container {
        padding: 10px 0 0;
    }

    .outer-franchises-container h2 {
        font-size: 20px;
    }

    .mobile-description{
        display: flex;
    }
}

@media only screen and (max-width: 380px) {
    .poster {
        max-width: 120px;
    }
}